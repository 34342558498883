<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">

                <div class="card elevation-5">
                    <div class="card-body">
                        
                        <v-data-table :headers="headers" :items="itemLists" class="elevation-1" page-count="5" :search="searchItem" :loading="loadingDatatable" dense>
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                        <div class="row">
                                            <div class="col-6">
                                                <!-- <v-text-field class="w-50" v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field> -->
                                            </div>
                                            <div class="col-6 text-right">
                                                <v-btn class="mt-3 mr-3" color="green" elevation="2" small rounded @click="showDialogAddUser()" dense dark>Add user<v-icon right dark>mdi-file-plus</v-icon></v-btn>
                                                <v-btn class="mt-3" color="primary" elevation="2" small rounded @click="populateUserList()" dense dark>Refresh<v-icon right dark>mdi-autorenew</v-icon></v-btn>
                                            </div>
                                        </div>
                                    </v-toolbar>
                            </template>
                            <template v-slot:[`item.mpf_approve`]="{ item }">
                                <v-chip v-if="item.mpf_approve == '1'" color="primary" class="" small dense dark>ALLOW</v-chip>
                                <v-chip v-else color="red" class="" small dense dark>NOT ALLOW</v-chip>
                            </template>
                            <template v-slot:[`item.active_flag`]="{ item }">
                                <v-chip v-if="item.active_flag == 'Y'" color="primary" class="" small dense dark>Y</v-chip>
                                <v-chip v-else color="red" class="" small dense dark>N</v-chip>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-menu offset-y>
                                    <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on">
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item link>
                                            <v-list-item-title class="ma-2" @click="editUser(item)">Edit User</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </template>
                        </v-data-table>

                    </div>
                </div>

            </div>

            <v-dialog v-model="dialogCreateUser" max-width="768px" >
            
                <div class="card elevation-5">
                    <div class="card-body ma-2">
                        <h5 class="card-title mb-4">Add MPF User</h5>
                        <div class="row">
                            <div class="col-lg-6 col-6">
                                <v-autocomplete v-model="addUserEntityModel" :items="addUserEntityLists" item-value="entity_id" item-text="description" label="Entity" @change="(event) => addUserEntityOnChange(event)" clearable dense prepend-icon="mdi-account"></v-autocomplete>
                            </div>
                            <div class="col-lg-6 col-6">
                                <v-autocomplete v-model="addUserIdModel" :items="addUserIdLists" item-value="user_id2" item-text="username" label="Username" clearable dense prepend-icon="mdi-account"></v-autocomplete>
                            </div>
                            <div class="col-lg-6 col-6">
                                <v-select v-model="addUserGroupModel" :items="addUserGroupLists" item-value="group_id" item-text="deskripsi" label="MPF Group" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-6 col-6">
                                <v-select v-model="addAllowApproveModel" :items="allowApproveLists" item-value="item_value" item-text="item_desc" label="Allow Approve" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-12 col-12 text-right">
                                <v-btn class="mt-3 mr-3" color="red" elevation="2" small rounded @click="dialogCreateUser = false" dark>Cancel</v-btn>
                                <v-btn class="mt-3" color="success" elevation="2" :disabled="disableSubmitUserbtn" small rounded @click="submitNewUser()">SUBMIT</v-btn>
                            </div>
                        </div>
                    </div>
                </div>
            
            </v-dialog>

            <v-dialog v-model="dialogEditUser" max-width="1024px" >
            
                <div class="card elevation-5">
                    <div class="card-body">
                        <h5 class="card-title">Edit MPF User</h5>
                        <div class="row">
                            <div class="col-lg-2 col-6">
                                <v-autocomplete v-model="editUserEntityModel" :items="editUserEntityLists" item-value="entity_id" item-text="description" label="Entity" disabled clearable dense prepend-icon="mdi-account"></v-autocomplete>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-autocomplete v-model="editUserIdModel" :items="editUserIdLists" item-value="user_id2" item-text="username" label="Username" disabled clearable dense prepend-icon="mdi-account"></v-autocomplete>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-select v-model="editUserGroupModel" :items="editUserGroupLists" item-value="group_id" item-text="group_id" label="MPF Group" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-select v-model="editAllowApproveModel" :items="allowApproveLists" item-value="item_value" item-text="item_desc" label="Allow Approve" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-select v-model="activeFlagModel" :items="activeFlagLists" item-value="item_value" item-text="item_desc" label="Active Flag" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-12 col-12 text-right">
                                <v-btn class="mt-3 mr-3" color="red" elevation="2" small rounded @click="dialogEditUser = false" dark>Cancel</v-btn>
                                <v-btn class="mt-3" color="success" elevation="2" :disabled="disableSubmitEditUserbtn" small rounded @click="submitEditUser()">SUBMIT</v-btn>
                            </div>
                        </div>
                    </div>
                </div>

            </v-dialog>

        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'Viva',
                disabled: false,
                href: '/admin/viva',
                },
                {
                text: 'MPF',
                disabled: false,
                href: '#',
                },
                {
                text: 'MPF User',
                disabled: true,
                href: '#',
                },
            ],
            headers: [
                { text: 'ENTITY', value: 'entity_id', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                { text: 'GROUP', value: 'group_id', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                { text: 'USERNAME', value: 'user_id2', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                { text: 'ALLOW APPROVE', value: 'mpf_approve', align: 'center', class: 'primary--text blue lighten-5', sortable:false },
                { text: 'ACTIVE FLAG', value: 'active_flag', align: 'center', class: 'primary--text blue lighten-5', sortable:false },
                { text: '', value: 'actions', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
            ],
            itemLists: [],
            searchItem: '',
            loadingDatatable: false,
            dialogCreateUser: false,
            addUserEntityModel: '',
            addUserEntityLists: [],
            addUserIdModel: '',
            addUserIdLists: [],
            addUserGroupModel: '',
            addUserGroupLists: [],
            addAllowApproveModel: '',
            editAllowApproveModel: '',
            allowApproveLists: [
                {
                    "item_value" : "1",
                    "item_desc" : "YES",
                },
                {
                    "item_value" : "0",
                    "item_desc" : "NO",
                }
            ],
            disableSubmitUserbtn: false,
            dialogEditUser: false,
            editUserEntityModel: '',
            editUserEntityLists: [],
            editUserIdModel: '',
            editUserIdLists: [],
            editUserGroupModel: '',
            editUserGroupLists: [],
            activeFlagModel: '',
            activeFlagLists: [
                {
                    "item_value" : "Y",
                    "item_desc" : "YES",
                },
                {
                    "item_value" : "N",
                    "item_desc" : "NO",
                }
            ],
            disableSubmitEditUserbtn: false
        }
    },
    async mounted(){
        
        this.initialize()
        
    },
    methods:{

        async initialize(){

            this.populateUserList()

        },

        async populateUserList(){

            this.$store.dispatch('setOverlay', true)
            this.loadingDatatable = true

            await axios.post(`${process.env.VUE_APP_URL}/api/viva/MPFUser/getData`, { 



            },
            {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
            })
            .then(res => {
                
                this.$store.dispatch('setOverlay', false)
                this.loadingDatatable = false

                this.itemLists = res.data.userList
                this.addUserIdLists = res.data.sec_user
                // this.addUserGroupLists = res.data.mpf_group
                this.editUserIdLists = res.data.sec_user
                // this.editUserGroupLists = res.data.mpf_group
                this.addUserEntityLists = res.data.entity
                this.editUserEntityLists = res.data.entity

                
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async submitNewUser(){

            this.disableSubmitUserbtn = true

            await axios.post(`${process.env.VUE_APP_URL}/api/viva/MPFUser/submitNewUser`, { 

                username: this.addUserIdModel ? this.addUserIdModel : '',
                group: this.addUserGroupModel ? this.addUserGroupModel : '',
                allowApprove: this.addAllowApproveModel ? this.addAllowApproveModel : ''

            },
            {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
            })
            .then(res => {

                if(res.data.result == true){

                    this.disableSubmitUserbtn = false
                    this.dialogCreateUser = false

                    Swal.fire({
                        icon: 'success',
                        text: 'MPF User Added Successfully !'
                    })

                    this.populateUserList()

                } else {

                    Swal.fire({
                        icon: 'warning',
                        text: 'MPF User Already exists !'
                    })

                    this.populateUserList()

                }
                
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        editUser(id){

            this.dialogEditUser = true
            this.editUserIdModel = id.user_id2
            this.editUserGroupModel = id.group_id
            this.editAllowApproveModel = id.mpf_approve
            this.activeFlagModel = id.active_flag

        },

        async submitEditUser(){

            this.disableSubmitEditUserbtn = true

            await axios.post(`${process.env.VUE_APP_URL}/api/viva/MPFUser/submitEditUser`, { 

                username: this.editUserIdModel ? this.editUserIdModel : '',
                group: this.editUserGroupModel ? this.editUserGroupModel : '',
                allowApprove: this.editAllowApproveModel ? this.editAllowApproveModel : '',
                active_flag: this.activeFlagModel ? this.activeFlagModel : '',

            },
            {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
            })
            .then(res => {

                if(res.data.result == true){

                    this.disableSubmitEditUserbtn = false
                    this.dialogEditUser = false

                    Swal.fire({
                        icon: 'success',
                        text: 'MPF User Modified Successfully !'
                    })

                    this.populateUserList()

                } else {

                    Swal.fire({
                        icon: 'warning',
                        text: 'There is something wrong !'
                    })

                    this.populateUserList()

                }
                
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })
            
        },

        async addUserEntityOnChange(id){

            this.addUserGroupLists = []

            await axios.get(`${process.env.VUE_APP_URL}/api/viva/MPFUser/getCategoryByEntity?entity=${id}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.addUserGroupLists = res.data.group

            })
            .catch(err => {
                
                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401') {
                    this.$router.push('/');
                } else {
                    Swal.fire({
                        text: err.response.data.data,
                        icon: 'error',
                    })
                }

            })
            
        },

        async showDialogAddUser(){

            this.dialogCreateUser = true

            this.addUserEntityLists = []
            this.addUserIdLists = []
            this.addUserGroupLists = []

            await axios.post(`${process.env.VUE_APP_URL}/api/viva/MPFUser/getData`, { 

            },
            {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
            })
            .then(res => {
                
                this.addUserEntityLists = res.data.entity
                this.addUserIdLists = res.data.sec_user
                
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        }

    },
    watch: {

        
    }
}

</script>